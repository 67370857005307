import React from 'react'
import PropTypes from 'prop-types'
import { Box, Image } from 'rebass/styled-components'

import pomoc from '../images/pomoc.svg'
import distributori from '../images/distributori.svg'
import priroda from '../images/priroda.svg'

const Benefit = ({ children, icon }) => (
  <Box variant="footerBenefit">
    <Image src={icon} alt="" aria-hidden />

    <Box variant="footerBenefitText">{children}</Box>
  </Box>
)

export const FooterBenefits = () => {
  return (
    <Box variant="footerBenefits">
      <Benefit icon={pomoc}>
        Pomoc s&nbsp;výběrem od&nbsp;profesionálů na&nbsp;777&nbsp;426&nbsp;811
      </Benefit>

      <Benefit icon={distributori}>
        Jsme oficiální distibutoři značek Verinlegno a Arboritec
      </Benefit>

      <Benefit icon={priroda}>
        Produkty i jejich aplikace jsou šetrné k&nbsp;přírodě
      </Benefit>
    </Box>
  )
}

Benefit.propTypes = {
  children: PropTypes.node.isRequired,
  icon: PropTypes.string.isRequired,
}
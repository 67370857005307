import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import {
  Box,
  Button,
  Link as RebassLink,
  Image,
} from 'rebass/styled-components'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'

import Container from './container'
import logo from '../images/frypo-logo.svg'
import ctaIcon from '../images/system-uicons_cart.svg'
// import brush from '../images/brush.svg'
// import { getRandomInt } from '../utils/getRandomInt'

const menu = [
  {
    label: 'Naše vize',
    link: '/nase-vize',
  },
  {
    label: 'Nabízíme',
    link: '/nabizime',
  },
  {
    label: 'Reference',
    link: '/reference',
  },
  {
    label: 'Kontakty',
    link: '/kontakt',
  },
]

// const Brush = () => (
//   <Box
//     className="brush"
//     variant="menuItemBrush"
//     sx={{ transform: `rotate(${getRandomInt(-5, 5)}deg)` }}
//   >
//     <Image src={brush} alt="" aria-hidden />
//   </Box>
// )

const Underline = () => (
  <Box className="underline" variant="menuItemUnderline" />
)

const MenuItem = ({ children, active, ...rest }) => (
  <RebassLink
    as={Link}
    variant={active ? 'menuItemActive' : 'menuItem'}
    {...rest}
  >
    <Box sx={{ position: 'relative', zIndex: 1 }}>{children}</Box>
    <Underline />
  </RebassLink>
)

const Header = ({ siteTitle, storeLink }) => {
  const [headerVariant, setHeaderVariant] = useState('header')
  const [navOpened, setNavOpened] = useState(false)

  useScrollPosition(({ currPos }) => {
    if (currPos.y < -30) {
      return setHeaderVariant('headerScrolled')
    }

    setHeaderVariant('header')
  })

  return (
    <Box
      variant={headerVariant}
      sx={{
        backdropFilter: navOpened ? 'blur(4px)' : null,
        bg: navOpened && 'rgba(0,57,84,0.95)',
      }}
      as="header"
    >
      <Container>
        <Box variant="headerContent">
          <Link to="/">
            <Image src={logo} alt={siteTitle} />
          </Link>

          <Button
            variant={navOpened ? 'menuToggleActive' : 'menuToggle'}
            onClick={() => setNavOpened(!navOpened)}
          >
            Menu
          </Button>

          <Box variant={navOpened ? 'navOpened' : 'nav'} as="nav">
            <Box variant="menu">
              {menu &&
                menu.length > 0 &&
                menu.map((menuItem) => {
                  const url =
                    typeof window !== 'undefined' ? window.location.href : ''

                  return (
                    <MenuItem
                      to={menuItem.link}
                      active={!!url.match(menuItem.link)}
                    >
                      {menuItem.label}
                    </MenuItem>
                  )
                })}
            </Box>

            {storeLink && (
              <RebassLink href={storeLink} target="_blank" variant="headerCTA">
                <Image src={ctaIcon} alt="" aria-hidden />
                E-shop
              </RebassLink>
            )}
          </Box>
        </Box>
      </Container>
    </Box>
  )
}

MenuItem.propTypes = {
  children: PropTypes.node.isRequired,
  active: PropTypes.bool,
}

Header.propTypes = {
  siteTitle: PropTypes.string,
  storeLink: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: '',
  storeLink: null,
}

export default Header

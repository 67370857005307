import { createGlobalStyle } from 'styled-components'

import listBullet from '../images/list-bullet.svg'

const GlobalStyle = createGlobalStyle`
  html {
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    -webkit-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
  }
  body {
    color: ${(props) => props.theme.colors.darkGreen};
    font-family: ${(props) => props.theme.fonts.body};
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  ul, p {
    margin-bottom: 1rem;
    line-height: 1.75;
  }

  ul {
    list-style: disc;
    list-style-image: url(${listBullet});
    padding-left: 1.5rem;
  }

  ul li {
    padding-left: 0.5rem;
  }

  strong {
    font-weight: bold;
  }
`

export default GlobalStyle

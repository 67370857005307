const colors = {
  white: 'white',
  green: '#49B96A',
  darkGreen: '#0B1D11',
  blue: '#003954',
  darkBlue: '#002638',
  headerBg: 'rgba(0, 57, 84, 0.95)',
}

const menuToggleLineHeight = 2

const transitions = {
  default: 'all 200ms cubic-bezier(.05,.85,.12,1)',
  menuItemBrush: 'all 200ms ease-in-out',
}

const light = {
  colors,
  breakpoints: ['40rem', '67.5rem', '80rem'],
  space: [0, 4, 8, 16, 32, 64, 128, 256],
  fonts: {
    body: 'Montserrat, Arial, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif',
    heading: 'inherit',
    monospace: 'Menlo, monospace',
  },
  radii: {
    button: '9999px',
  },
  shadows: {
    card: '0px 8px 96px rgba(11, 29, 17, 0.1)',
  },
  transitions,
  // Buttons
  buttons: {
    px: 4,
    py: 3,
    borderRadius: 'button',
    fontFamily: 'body',
    textAlign: 'center',
    // Button variants
    menuToggle: {
      display: ['block', 'none'],
      justifySelf: 'end',
      position: 'relative',
      minWidth: '1.5rem',
      py: 2,
      px: 3,
      bg: 'transparent',
      border: 'none',
      cursor: 'pointer',
      textIndent: '-9999px',
      transformOrigin: '50% 50%',
      '&:after, &:before': {
        position: 'absolute',
        content: '""',
        width: '1.5rem',
        height: `${menuToggleLineHeight}px`,
        top: 0,
        left: 0,
        bg: 'white',
        transition: transitions.default,
        transformOrigin: '50% 50%',
      },
      '&:after': {
        top: `calc(0.75rem - ${menuToggleLineHeight / 2}px)`,
      },
      '&:before': {
        top: `calc(1.25rem - ${menuToggleLineHeight / 2}px)`,
      },
    },
    menuToggleActive: {
      variant: 'buttons.menuToggle',
      '&:after': {
        transform: 'rotate(45deg)',
        top: '1rem',
      },
      '&:before': {
        transform: 'rotate(-45deg)',
        top: '1rem',
      },
    },
    headerCTA: {
      display: 'grid',
      gridAutoFlow: 'column',
      alignItems: 'center',
      px: '24px',
      py: 2,
      gap: 2,
      bg: 'green',
      borderRadius: 'button',
      color: 'darkGreen',
      fontFamily: 'body',
      fontSize: '1.125rem',
      fontWeight: 'bold',
      lineHeight: 1,
      textAlign: 'center',
      transition: transitions.default,
      '&:hover': {
        bg: '#fff',
      },
    },
    primary: {
      display: 'grid',
      gridAutoFlow: 'column',
      alignItems: 'center',
      px: 4,
      py: 3,
      gap: 2,
      bg: 'blue',
      borderRadius: 'button',
      color: 'white',
      fontFamily: 'body',
      fontSize: '1.125rem',
      fontWeight: 'bold',
      lineHeight: 1.25,
      textAlign: 'center',
      transition: transitions.default,
      '&:hover': {
        bg: 'darkBlue',
      },
    },
    alertClose: {
      border: 'none',
      bg: 'transparent',
      color: 'white',
      cursor: 'pointer',
      fontSize: '1.5rem',
      outline: 'none',
      position: 'absolute',
      top: '50%',
      right: '0.5rem',
      transform: 'translateY(-55%)',
    },
  },
  text: {
    heroTitle: {
      display: 'inline-block',
      position: 'relative',
      color: 'white',
      fontSize: ['1.75rem', '3.5rem'],
      fontWeight: 'bold',
      lineHeight: 1.25,
      letterSpacing: '-1px',
      textAlign: ['center', 'left'],
    },
    heroTitleBig: {
      variant: 'text.heroTitle',
      maxWidth: '26rem',
      fontSize: ['2rem', '4.5rem'],
      textAlign: ['left'],
      '&:after': {
        position: 'absolute',
        content: '""',
        width: ['9px', '17px'],
        height: ['12px', '23px'],
        top: ['82%', '87%'],
        right: ['30%', '32%'],
        backgroundSize: '100%',
        backgroundImage:
          'url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTciIGhlaWdodD0iMjMiIHZpZXdCb3g9IjAgMCAxNyAyMyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE0LjA2NyAyMS4xMTExQzE1LjYyOTIgMTkuNzU0NSAxNi43MzgzIDE3Ljk2MzcgMTYuOTQ1MyAxNS44NzA4QzE2Ljk0NTMgMTUuODcwOCAxNy4wOTkyIDE0LjYwODUgMTYuODkwOCAxMy4wNDAyQzE2LjY1MTYgMTEuMjM0NSAxNi4yMDk3IDkuNDg1NTEgMTUuNTExOCA3Ljc5MDQxQzE0LjM1MjMgNC45Nzc0IDEyLjc0NjggMi40MTY1NSAxMC44ODggMEwxMC43Mzk4IDAuMjA2MzI0QzEwLjczOTggMC4yMDYzMjQgOS43OTU3NCAxLjUxNzA5IDkuNTQ1MzkgMS44NDYxM0M3Ljc3NDc4IDQuMTY1NTkgNS43MjE2NSA1Ljk0Mjk0IDUuNzIxNjUgNS45NDI5NEM0LjcxMzI3IDYuOTI3MzYgMy41MzcwNiA3Ljg2OTk4IDIuNTczNDMgOC44OTIxNkMxLjQxOTYgMTAuMTE4IDAuNjY3MTYxIDExLjQ2MjQgMC4zMTA1MjEgMTIuOTE0OEMtMC4zMzE0MyAxNC44Mzc4IDAuMjIxMDEzIDE3LjA0NCAwLjIyMTAxMyAxNy4wNDRDMS4wNDc1OCAyMC4zMjA5IDQuMDE2NzggMjIuNzU5IDcuODgzODcgMjIuOTgwMkM3Ljg4Mzg3IDIyLjk4MDIgOS44Njk4NiAyMy4xODY1IDEyLjExNiAyMi4yNjgyQzEyLjExNiAyMi4yNjgyIDEzLjIxNjcgMjEuODUwMSAxNC4wNjcgMjEuMTExMVoiIGZpbGw9IiM0N0I5NkEiLz4KPC9zdmc+Cg==")',
      },
    },
    heroText: {
      maxWidth: '26rem',
      color: 'white',
      fontSize: '1.125rem',
      lineHeight: 1.75,
    },
    heroTextLead: {
      variant: 'text.heroText',
      maxWidth: 'none',
      fontSize: ['1rem', '1.5rem'],
      fontWeight: 'bold',
      textAlign: ['center', 'left'],
      textTransform: 'uppercase',
    },
    contentSectionTitle: {
      color: 'green',
      fontSize: '2rem',
      fontWeight: 'bold',
      letterSpacing: '-1px',
      lineHeight: 1.25,
    },
    // Card
    cardTitle: {
      color: 'green',
      fontSize: '1.5rem',
      fontWeight: 'bold',
    },
    cardText: {
      fontSize: '1.125rem',
      lineHeight: 1.75,
    },
    // Team
    teamMemberName: {
      color: 'white',
      fontSize: ['1.5rem', '1rem', '1.5rem'],
      fontWeight: 'bold',
      letterSpacing: '-1px',
      textTransform: 'uppercase',
    },
    teamMemberPosition: {
      color: 'white',
      fontSize: ['15px', '12px', '15px'],
    },
    // Contact item
    contactItemHeading: {
      color: 'blue',
      fontSize: '1rem',
      fontWeight: 'bold',
      letterSpacing: '-1px',
      lineHeight: 1.75,
      textTransform: 'uppercase',
    },
    contactItemLabel: {
      color: 'darkGreen',
      fontSize: '1.125rem',
      lineHeight: 1.75,
    },
    contactItemText: {
      color: 'green',
      fontSize: '2rem',
      fontWeight: 'bold',
      lineHeight: 1.2,
      '& a': {
        color: 'inherit',
      },
    },
    // Phone item
    phoneItemHeading: {
      color: 'darkBlue',
      fontSize: '0.875rem',
      lineHeight: 1,
    },
    phoneItemNumber: {
      color: 'blue',
      fontSize: '1.5rem',
      '& a': {
        display: 'block',
        color: 'inherit',
      },
    },
    // Address
    addressTitle: {
      color: 'darkBlue',
      fontSize: '0.875rem',
      lineHeight: 1,
    },
    addressHeading: {
      color: 'green',
      fontSize: '0.875rem',
      fontWeight: 'bold',
      lineHeight: 1.75,
      textTransform: 'uppercase',
    },
    addressContent: {
      color: 'blue',
      fontSize: '1.5rem',
      lineHeight: 1.5,
    },
    // Footer
    footerCopyright: {
      fontSize: '0.75rem',
      lineHeight: 1.75,
    },
  },
  variants: {
    // Header
    header: {
      position: 'fixed',
      top: 0,
      right: 0,
      left: 0,
      py: 4,
      transition: 'padding 300ms ease-out',
      backgroundColor: 'transparent',
      backgroundImage:
        'linear-gradient(180deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0) 100%)',
      zIndex: 5,
    },
    headerScrolled: {
      variant: 'variants.header',
      py: 3,
      backgroundColor: 'headerBg',
      backgroundImage: 'none',
    },
    headerContent: {
      display: 'grid',
      gridAutoFlow: ['row', 'column'],
      alignItems: 'start',
      justifyContent: 'space-between',
      justifyItems: 'stretch',
      rowGap: [0, 4],
      gridTemplateColumns: ['auto 1fr', 'auto 1fr'],
      gridTemplateRows: ['auto 1fr', 'auto'],
    },
    // Nav
    nav: {
      display: ['grid', 'grid'],
      maxHeight: [0, 'none'],
      gridAutoFlow: ['row', 'column'],
      alignItems: 'center',
      justifyItems: ['start', 'end'],
      rowGap: 4,
      overflow: 'hidden',
      transition: transitions.default,
    },
    navOpened: {
      variant: 'variants.nav',
      display: 'grid',
      overflow: 'visible',
      maxHeight: '100%',
    },
    // Menu
    menu: {
      display: 'grid',
      gridAutoFlow: ['row', 'column'],
      gap: [3, 3, 4],
      justifyItems: 'start',
    },
    menuItem: {
      display: 'inline-block',
      position: 'relative',
      py: 2,
      cursor: 'pointer',
      color: 'white',
      fontSize: ['1rem', '0.85rem', '1rem'],
      fontWeight: 'bold',
      letterSpacing: [0, '-1px', 0],
      textTransform: 'uppercase',
      transition: transitions.menuItemBrush,
      '&:first-of-type': {
        mt: [4, 0],
      },
      '&:hover .brush': {
        clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)',
      },
      '&:hover .underline': {
        transform: 'scaleX(1)',
      },
    },
    menuItemActive: {
      variant: 'variants.menuItem',
      '& .underline': {
        transform: 'scaleX(1)',
      },
    },
    menuItemBrush: {
      position: 'absolute',
      top: '-30%',
      left: '-20%',
      width: '140%',
      height: '140%',
      transition: transitions.menuItemBrush,
      clipPath: 'polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%)',
    },
    menuItemUnderline: {
      position: 'absolute',
      top: 'calc(100% - 2px)',
      left: '0',
      width: '100%',
      height: '2px',
      bg: 'green',
      borderRadius: '2px',
      transition: transitions.default,
      transformOrigin: '50% 50%',
      transform: 'scaleX(0)',
    },
    // Alert
    alert: {
      position: 'fixed',
      right: 3,
      bottom: 3,
      left: 3,
      p: 3,
      bg: 'blue',
      color: 'white',
      boxShadow: 'card',
      fontSize: '0.75rem',
      fontWeight: 'bold',
      textAlign: 'center',
      textTransform: 'uppercase',
      zIndex: '10',
    },
    // Hero
    hero: {
      minHeight: ['40vh', '50vh'],
      pb: [3, 4],
      position: 'relative',
      backgroundColor: 'black',
      backgroundPosition: 'center 30%',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      '& > div': {
        minHeight: ['40vh', '50vh'],
      },
    },
    heroFullHeight: {
      variant: 'variants.hero',
      minHeight: '100vh',
      pb: 0,
      backgroundAttachment: ['scroll', 'fixed'],
      '& > div': {
        minHeight: '100vh',
      },
    },
    heroOverlay: {
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      bg: 'rgba(11, 29, 17, 0.4)',
      width: '100%',
      height: '100%',
    },
    heroBody: {
      display: 'grid',
      position: 'relative',
      justifyContent: 'stretch',
      alignItems: 'center',
      color: 'white',
      left: '-4px',
    },
    heroContent: {
      display: 'grid',
      gap: 4,
    },
    heroLink: {
      display: 'grid',
      gridAutoFlow: 'column',
      gap: 3,
      alignItems: 'center',
      color: 'inherit',
      cursor: 'pointer',
      justifyContent: 'start',
      fontSize: '1rem',
      fontWeight: 'bold',
      textTransform: 'uppercase',
      img: {
        transition: transitions.default,
      },
      '&:hover img': {
        transform: 'translateX(-0.5rem)',
      },
      '&:active img, &:focus img': {
        transform: 'translateX(0.5rem)',
      },
    },
    // Footer
    footer: {
      backgroundColor: 'blue',
      color: 'white',
    },
    footerUpper: {
      py: 4,
      bg: '#F4FBF6',
      color: 'blue',
      fontWeight: 'bold',
      lineHeight: 1.3,
    },
    footerLower: {
      py: '3rem',
    },
    footerBenefits: {
      display: 'grid',
      gridAutoFlow: ['row', 'column'],
      gap: 3,
      gridTemplateColumns: ['1fr', '1fr 1fr 1fr'],
    },
    footerBenefit: {
      display: 'grid',
      gridAutoFlow: 'column',
      gap: 3,
      alignItems: 'center',
    },
    footerBenefitText: {
      maxWidth: ['none', '80%'],
    },
    footerLowerContent: {
      display: 'grid',
      gap: [4, 3],
    },
    footerLogoContactDrop: {
      display: 'grid',
      gridAutoFlow: ['column', 'column'],
      justifyContent: 'space-between',
      gap: [3, 0],
    },
    footerLogoContact: {
      display: 'grid',
      gridAutoFlow: ['row', 'column'],
      justifyContent: 'start',
      gap: [3, 5],
      a: {
        color: 'white',
        fontSize: '1.5rem',
      },
    },
    // Card
    card: {
      display: 'grid',
      p: 4,
      alignItems: 'start',
      justifyItems: 'start',
      bg: 'white',
      boxShadow: 'card',
    },
    // Team
    team: {
      position: 'relative',
      display: 'grid',
      gridAutoFlow: ['row', 'row'],
      gridTemplateColumns: ['auto', 'repeat(2, 1fr)', 'repeat(5, 1fr)'],
      gridTemplateRows: ['auto', 'repeat(2, 1fr)', 'auto'],
    },
    teamMember: {
      position: 'relative',
      '& img': {},
    },
    teamMemberContent: {
      position: 'absolute',
      display: 'grid',
      alignContent: 'center',
      gap: 2,
      top: '50%',
      p: 3,
      right: 0,
      bottom: 0,
      left: 0,
      background:
        'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 75%);',
    },
    // Pillar
    pillars: {
      display: 'grid',
      gridTemplateColumns: ['auto', 'repeat(3, 1fr)'],
      rowGap: 3,
      justifyItems: 'space-evenly',
      width: '100%',
      mt: 4,
    },
    pillar: {
      display: 'grid',
      justifyItems: 'center',
      gap: 2,
    },
    pillarNumber: {
      display: 'grid',
      alignItems: 'center',
      justifyItems: 'center',
      width: '4rem',
      height: '4rem',
      borderRadius: '9999px',
      bg: 'green',
      color: 'blue',
      fontSize: '2rem',
      fontWeight: 'bold',
      lineHeight: 1,
    },
    // Services
    serviceFooter: {
      display: 'grid',
      width: '100%',
      gridAutoFlow: ['row', 'column'],
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: 4,
    },
    // Sections
    sections: {
      content: {
        py: 5,
        boxContent: {
          display: 'grid',
          gap: '1.5rem',
          justifyItems: 'start',
        },
      },
      contentDark: {
        variant: 'variants.sections.content',
        color: 'white',
        '& *': {
          color: 'white',
        },
      },
      intro: {
        py: 5,
        boxContent: {
          display: 'grid',
          gridAutoFlow: ['row', 'column'],
          gridTemplateColumns: ['1fr', '1fr 3% 68%'],
          alignItems: 'start',
        },
        image: {
          gridColumnStart: ['auto', 1],
          gridColumnEnd: ['auto', 2],
          gridRowStart: ['auto', 1],
          order: [2, 1],
        },
        textContent: {
          position: 'relative',
          gridColumnStart: ['auto', 2],
          gridColumnEnd: ['auto', 4],
          gridRowStart: ['auto', 1],
          order: [1, 2],
        },
      },
      store: {
        py: 5,
        boxContent: {
          display: 'grid',
          gridAutoFlow: ['row', 'column'],
          gridTemplateColumns: ['1fr', '1fr 5% 65%'],
          gap: [4, 0],
        },
        textContent: {
          display: 'grid',
          gap: 3,
          alignContent: 'start',
          justifyItems: ['center', 'start'],
          color: 'white',
          gridRowStart: ['auto', 1],
          gridColumnStart: ['auto', 1],
          gridColumnEnd: ['auto', 2],
          textAlign: ['center', 'left'],
        },
        image: {
          position: 'relative',
          mt: [0, 4, '-80px'],
          mb: [0, 0, '-80px'],
          gridRowStart: ['auto', 1],
          gridColumnStart: ['auto', 2],
          gridColumnEnd: ['auto', 4],
        },
      },
      contactUs: {
        py: 5,
        boxContent: {
          display: 'grid',
          gridAutoFlow: ['row', 'column'],
          gridTemplateColumns: ['1fr'],
          gap: [4, 0],
        },
        textContent: {
          display: 'grid',
          gap: 3,
          alignContent: 'center',
          alignItems: 'center',
          justifyContent: ['center', 'space-between'],
          justifyItems: ['center', 'space-between'],
          color: 'white',
          fontSize: '1rem',
          textAlign: ['center', 'left'],
        },
      },
      reference: {
        py: 5,
      },
    },
    // Links
    headerCTA: {
      variant: 'buttons.headerCTA',
    },
    linkButton: {
      variant: 'buttons.primary',
    },
    // Carousel
    carousel: {
      button: {
        display: ['none', 'none', 'block'],
        position: 'absolute',
        top: 'calc(50% - 2rem)',
        py: '1.5rem',
        px: 2,
        bg: 'white',
        borderRadius: 'none',
        border: 'none',
        boxShadow: 'card',
        fontSize: 0,
        lineHeight: 0,
        outline: 'none',
      },
      buttonLeft: {
        variant: 'variants.carousel.button',
        left: 0,
        transform: 'translateX(-1rem)',
      },
      buttonRight: {
        variant: 'variants.carousel.button',
        right: 0,
        transform: 'translateX(1rem)',
      },
      dotGroup: {
        display: 'grid',
        gridAutoFlow: 'column',
        justifyContent: 'center',
        gap: 0,
        '& .carousel__dot--selected > div': {
          bg: 'blue',
        },
      },
      dot: {
        bg: 'transparent',
        border: 'none',
        p: 0,
        py: '1rem',
      },
      dotLine: {
        bg: '#DCE3E6',
        width: '3rem',
        height: '0.125rem',
        transition: transitions.default,
      },
    },
  },
  styles: {
    strong: {
      fontWeight: 'bold',
    },
  },
}

export default light

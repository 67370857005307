import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import { Reset } from 'styled-reset'
import { ThemeProvider } from 'styled-components'

import GlobalStyle from '../styles/globalStyle'
import light from '../themes/default'
import { Alert } from './alert'
import Header from './header'
import Footer from './footer'

export const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          storeLink
          contacts {
            email
            phone
          }
        }
      }
    }
  `)
  const { email, phone } = data.site.siteMetadata.contacts
  const { title, storeLink } = data.site.siteMetadata

  return (
    <ThemeProvider theme={light}>
      <Reset />
      <GlobalStyle />

      <Alert alertId="07.11.2021" hideAfter={new Date(2022, 0, 3)}>
        Provozní doba o svátcích: 20.12.2021 do 14:30. Od 21.12.2021—2.1.2022
        zavřeno. Od <strong>3.1.2022</strong> běžná provozní doba.
      </Alert>

      <Header siteTitle={title} storeLink={storeLink} />

      <main>{children}</main>

      <Footer contacts={{ email, phone }} />
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

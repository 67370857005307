import React from 'react'
import PropTypes from 'prop-types'
import { Box, Image, Link, Text } from 'rebass/styled-components'

import Container from './container'
import logo from '../images/frypo-logo.svg'
import { FooterBenefits } from './footer-benefits'

const Footer = ({ contacts }) => (
  <Box variant="footer" as="footer">
    <Box variant="footerUpper">
      <Container>
        <FooterBenefits />
      </Container>
    </Box>

    <Box variant="footerLower">
      <Container>
        <Box variant="footerLowerContent">
          <Box variant="footerLogoContactDrop">
            <Box variant="footerLogoContact">
              <Image src={logo} alt="" aria-hidden />

              {contacts.phone && (
                <Link href={`tel:${contacts.phone.replace(/ /gi, '')}`}>
                  <strong>{contacts.phone}</strong>
                </Link>
              )}

              {contacts.email && (
                <Link href={`mailto:${contacts.email}`}>
                  <strong>{contacts.email}</strong>
                </Link>
              )}
            </Box>
          </Box>

          <Box>
            <Text variant="footerCopyright">
              {`Copyright © ${new Date().getFullYear()} FRYPO wood design s.r.o. Všechna práva vyhrazena. Společnost vedená u Krajského soudu v Ostravě, spisová značka C 62588.`}
            </Text>
            <Text variant="footerCopyright">
              Web vytvořili ve{' '}
              <a
                href="https://ctyri.media"
                target="_blank"
                rel="noopener noreferrer"
              >
                ctyri.media
              </a>
              .
            </Text>
          </Box>
        </Box>
      </Container>
    </Box>
  </Box>
)

Footer.propTypes = {
  contacts: PropTypes.shape({
    email: PropTypes.string,
    phone: PropTypes.string,
  }),
}

export default Footer

import React from 'react'
import PropTypes from 'prop-types'
import { Box } from 'rebass/styled-components'

const Container = ({ children, width = 1040, px = 0, sx }) => (
  <Box
    sx={{
      maxWidth: width,
      mx: 'auto',
      px: px || [3, 3, 0],
      ...sx
    }}
  >
    {children}
  </Box>
)

Container.propTypes = {
  children: PropTypes.node.isRequired,
  width: PropTypes.number,
  px: PropTypes.number,
  sx: PropTypes.object,
}

Container.defaultProps = {
  width: 1040,
  px: 0,
  sx: {},
}

export default Container

import React from 'react'
import PropTypes from 'prop-types'
import { Box, Button } from 'rebass/styled-components'

import { useLocalStorage } from '../hooks/useLocalStorage'

export const Alert = ({ children, alertId, hideAfter }) => {
  const [alertHidden, setAlertHidden] = useLocalStorage(
    `alert-${alertId}`,
    false
  )

  if (alertHidden) return null

  if (hideAfter && Date.parse(hideAfter) < Date.now()) {
    return null
  }

  return (
    <Box variant="alert">
      {children}{' '}
      <Button
        variant="alertClose"
        type="button"
        onClick={() => setAlertHidden(true)}
      >
        ×
      </Button>
    </Box>
  )
}

Alert.propTypes = {
  children: PropTypes.node.isRequired,
  alertId: PropTypes.string.isRequired,
  hideAfter: PropTypes.any,
}
